@media screen and (min-width: 2000px) {
  html {
    zoom: 1.75;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 5px;
  font-family: Arial, Helvetica, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-size: cover;
  background-color: #000;
}

.count {
  font-weight: 600 !important;
}

.slide-down-enter {
  opacity: 0;
  max-height: 0;
}

.slide-down-enter-active {
  transition: max-height 500ms ease-out, opacity 300ms ease-out;
  opacity: 1;
  max-height: 120px;
}

.slide-down-exit {
  opacity: 1;
}

.slide-down-exit-active {
  opacity: 0;
  transition: max-height 500ms ease-out, opacity 300ms ease-out;
  max-height: 0;
}
.slide-down-exit-active span {
  text-decoration: line-through;
}

.coffee-icon {
  margin-right: 1rem;
  vertical-align: middle;
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.featured-table-cell-badge.first {
  background: gold;
}

.featured-table-cell-badge.second {
  background: silver;
}

.featured-table-cell-badge.third {
  background: chocolate;
}
.featured-table-cell-badge.lunch-badge.soup {
  background: rgb(255, 106, 0);
}
.featured-table-cell-badge.lunch-badge.meal {
  background: rgb(41, 41, 41);
}

.featured-table-cell-badge {
  font-size: 1em;
  padding: 10px 30px;
  border-radius: 15px;
  font-weight: bold;
}
.featured-table-cell-badge.lunch-badge {
  padding: 10px 10px;
}
.featured-table-cell-badge.lunch-badge > *{
  vertical-align: middle;
}

.transit-line-badge {
  font-size: 1em;
  padding: 5px;
  border-radius: -5px;
  font-weight: bold;
  margin-right: 2px;
  width: 40px;
  text-align: center;
  font-weight: bold;
}
.transit-line-badge.normal {
  background-color: #0089ff;
}
.transit-line-badge.night {
  background-color: crimson;
}

.featured-table-cell-badge .coffee-icon {
  margin-right: 0px;
}

.background-slider {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-repeat: no-repeat; 
  background-size: cover;
  background-position: center center;
  visibility: visible;
}